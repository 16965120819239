import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {appSettings} from '../app-settings';
import {StorageKeys, StorageService} from './storage.service';
import {environment} from '../../environments/environment';
import {Platform} from '@ionic/angular';
import {from} from 'rxjs/internal/observable/from';
import {Chat} from '../components/chat/chat';
import {ToastService} from './toast.service';
import {Support} from '../pages/clienti/cliente-detail/supporto/support';
import {Language} from '../helpers/language';
import {Payment} from '../pages/clienti/cliente-detail/payment/payment';
import {UtilityHelper} from '../helpers/utility.helper';
import {IMenuClientInfo} from '../models/menu-clinet-info';
import {ISection} from '../interfaces/ISection';
import {PageTemplateOption} from '../models/page-template-option.model';
import {PermissionCode} from '../enums/permission-code.enum';
import {BookingInfo, InfoSalone, UserProfileInfo} from '../models/user-profile.model';
import {Collaboratori, RegistrationCollaboratoreInfo} from '../models/collaboratore.model';
import {ContactRequest} from '../models/contact-request.model';
import {Clienti} from '../models/cliente.model';
import {RegistrationUserInfo} from '../models/registration-user.model';
import {ChangePasswordInfo} from '../models/change-password.model';
import {ResetPasswordInfo} from '../models/reset-password.model';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    public user: AuthResponseData;
    public urlForce = '';
    public isDraft = false;
    counter = 0;
    counterShowId = 0;
    public currentLanguage: Language = null;
    public darkMode = false;

    constructor(
        private http: HttpClient,
        private platform: Platform,
        private toastService: ToastService,
        private storageService: StorageService,
    ) {
    }

    private _currentUserInfo$ = new BehaviorSubject<UserProfileInfo>(null);

    get currentUserInfo$() {
        return this._currentUserInfo$.asObservable();
    }

    public _userPromise: Promise<AuthResponseData>;

    get userPromise() {
        if (!this._userPromise)
            this._userPromise = firstValueFrom(
                this.getCurrentUserInfo().pipe(
                    map(() => {
                        return this.user;
                    })
                )
            )
        return this._userPromise;
    }

    get appName(): string {
        return environment.appName;
    }

    get website() {
        return [...this.currentUserInfo?.MenuWebsites].sort((a, b) => {
            if (a.IsActive === b.IsActive)
                return a.IsPrimary ? -1 : 1
            else
                return a.IsActive ? -1 : 1
        })[0]?.WebsiteAddress;
    }

    get isAdmin() {
        return this.user && this.user.roles &&
            (this.user.roles.includes('Admin') || this.user.roles.includes('Sede'));
    }

    get isCliente() {
        return this.user && this.user.roles && this.user.roles.includes('Cliente');
    }

    get isCollaboratore() {
        return this.user && this.user.roles && this.user.roles.includes('Collaboratore');
    }

    get isAutomaticAppointment() {
        return this.currentUserInfo?.IsAutomaticAppointment ?? false;
    }

    get IsCompletedAppointmentHidden() {
        return this.currentUserInfo?.IsCompletedAppointmentHidden ?? false;
    }

    get IsCheckAppUser() {
        return this.currentUserInfo?.IsCheckAppUser ?? false;
    }

    get IsCashRegisterClosed() {
        if (this.currentUserInfo?.CashRegisterCloseTime) {
            const twoDaysAgo = new Date();
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
            const lastClosedTime = new Date(this.currentUserInfo?.CashRegisterCloseTime);
            lastClosedTime.setHours(6);
            lastClosedTime.setMinutes(0);
            console.log(lastClosedTime);
            console.log(twoDaysAgo);
            console.log(lastClosedTime.getTime() > twoDaysAgo.getTime());
            return lastClosedTime.getTime() > twoDaysAgo.getTime();
        }
        return false;
    }

    get firstName() {
        return this.user?.firstName;
    }

    get isCustomer() {
        return this.user && this.user.roles && this.user.roles.includes('Customer');
    }

    get isSalone() {
        return (this.user && this.user.firstName &&
            (this.user.firstName.toLowerCase().trim() === 'salone' ||
                this.user.firstName.toLowerCase().trim() === 'reception'));
    }

    get isNotReception() {
        return this.user?.firstName?.toLowerCase()?.trim() !== 'reception';
    }

    get getCurrentUser(): UserProfileInfo {
        return this.currentUserInfo;
    }

    get getCurrentUserClientId(): number {
        return this.getCurrentUser.profiloCliente;
    }

    get getCurrentUserFlagMagazzinoAdmin(): boolean {
        return (
            this.getCurrentUser.flagViewMagazzino ||
            this.getCurrentUser.flagViewMagazzinoAdmin
        );
    }

    get getIsMultiLineAppointment(): boolean {
        return this.getCurrentUser.IsMultiLineAppointment;
    }

    get clientId() {
        return this.currentUserInfo.Id;
    }

    private _currentUserInfo: UserProfileInfo = null;

    public get currentUserInfo() {
        return this._currentUserInfo;
    }

    public set currentUserInfo(user: UserProfileInfo) {
        this._currentUserInfo = user;
        if (this._currentUserInfo) {
            this.currentLanguage = this.languages?.find(l => l.Id === this._currentUserInfo.LinguaDefaultFleep);
        }
    }

    private _languages: Language[] = [];

    public get languages() {
        return this._languages;
    }

    public set languages(languages: Language[]) {
        this._languages = languages;
        if (this._currentUserInfo) {
            this.currentLanguage = this.languages?.find(l => l.Id === this._currentUserInfo.LinguaDefaultFleep);
        }
    }

    getPermissionCodes() {
        let codes = [];
        const user = this.getCurrentUser;

        if (this.isCliente) {
            codes = [
                ...codes,
                PermissionCode.Anagrafica,
                PermissionCode.CashRegister,
                PermissionCode.SalonSettings,
                PermissionCode.BookingSettings,
                PermissionCode.Targets,
                PermissionCode.Services,
                PermissionCode.Collaborators,
            ];

            if (user.flagViewMagazzino) codes.push(PermissionCode.Warehouse);
            if (user.flagViewPromotionsAdmin) codes.push(PermissionCode.Promotions);
            if (user.flagAutomaticWhatsAppEnabled) codes.push(PermissionCode.WhatsAppSettings);
            if (user.flagAutomaticWhatsAppEnabledAdmin) codes.push(PermissionCode.WhatsAppSettingsAdmin);
            if (user.IsCheckAppUser) codes.push(PermissionCode.Expenses);

        } else if (this.isAdmin)
            codes = [
                ...codes,
                PermissionCode.Anagrafica,
                PermissionCode.CashRegister,
                PermissionCode.SalonSettings,
                PermissionCode.BookingSettings,
                PermissionCode.Targets,
                PermissionCode.Services,
                PermissionCode.Collaborators,
                PermissionCode.Clients,
                PermissionCode.Support,
                PermissionCode.Warehouse,
                PermissionCode.WhatsAppSettings,
                PermissionCode.WhatsAppSettingsAdmin,
                PermissionCode.Expenses
            ];

        else if (this.isCollaboratore) {
            if (user.flagViewRegistratoreDiCassa) codes.push(PermissionCode.CashRegister);
            if (user.flagViewMagazzino) codes.push(PermissionCode.Warehouse);
            if (user.flagViewPromotionsAdmin) codes.push(PermissionCode.Promotions);
        }

        return codes;
    }

    public async checkCurrentUser() {
        if (!this.user) {
            let userJson = await this.storageService.get(StorageKeys.user);
            if (!userJson)
                userJson = sessionStorage.getItem(StorageKeys.user);

            if (userJson) {
                this.user = JSON.parse(userJson);
            }
        }
    }

    setCashRegisterCloseTime(time: Date) {
        this.currentUserInfo.CashRegisterCloseTime = time;
    }

    download(type: string, id: number, includeDeleted: boolean = true) {
        let params = new HttpParams();
        params = params.set('includeDeleted', includeDeleted);

        return this.http.get(
            appSettings.API.REPORTS + `/${type}?IdCliente=${id}`,
            {
                responseType: 'blob',
                params: params
            }
        );
    }

    downloadMenu(type: string, id: number, includeDeleted: boolean) {
        let params = new HttpParams();
        params = params.set('includeDeleted', includeDeleted);

        return this.http.get(
            appSettings.API.REPORTSMENU + `/${type}?IdCliente=${id}`,
            {
                responseType: 'blob',
                params: params
            }
        );
    }

    downloadRpt7(email: string) {
        return this.http.get(
            appSettings.API.REPORTS + `/rpt7/GetByUserId(${email},1,1)`,
            {
                responseType: 'blob' as 'json'
            }
        );
    }

    uploadFile(file: File, type: string, id: number) {
        const uploadData = new FormData();
        uploadData.append('file', file, file.name);
        return this.http.post<boolean>(
            appSettings.API.PARSER + `/upload/${type}?IdCliente=${id}`,
            uploadData
        );
    }

    uploadFileMenu(file: File, type: string, id: number) {
        const uploadData = new FormData();
        uploadData.append('file', file, file.name);
        return this.http.post<boolean>(
            appSettings.API.PARSERMENU + `/upload/${type}?IdCliente=${id}`,
            uploadData
        );
    }

    getCurrentUserInfo() {
        return this.http.get<UserProfileInfo>(
            appSettings.API.CLIENTI + `/currentUserInfo`
        ).pipe(
            tap(resp => {
                if (resp.isLoginForced) {
                    window.location.href = '/login';
                }
            }),
            tap(result => {
                this.setCurrentUserInfo(result);
            })
        );
    }

    getCurrentCollaborator() {
        return this.http.get<Collaboratori>(
            appSettings.API.COLLABORATORI + `/current`
        );
    }

    updateUserprofile(userInfo: UserProfileInfo) {
        return this.http.put<UserProfileInfo>(
            `${appSettings.API.CLIENTI}`,
            {...userInfo}
        ).pipe(
            tap(() => this.setCurrentUserInfo(userInfo))
        );
    }

    sendLog(error: string) {
        return this.http.post(
            `${appSettings.API.CONFIG}/senderror`,
            {error}
        );
    }

    getCategoriaAnalisi() {
        return this.http.get<{ Id: number, Categoria: string }[]>(
            `${appSettings.API.API}/CategorieAnalisiDati`
        );
    }

    sendContactRequest(contactsRequest: ContactRequest) {
        return this.http.post<Clienti>(
            `${appSettings.API.CLIENTI}/Contacts`,
            contactsRequest
        );
    }

    acceptTerms() {
        return this.http.put<Clienti>(
            `${appSettings.API.CLIENTI}/TermsAccept`,
            null
        );
    }

    saveBooking(formBooking: BookingInfo) {
        const body = new FormData();
        for (const file of formBooking.files) {
            body.append('files', file, file.name);
        }
        body.append('infoSalone', JSON.stringify(formBooking.infoSalone));
        return this.http.post(`${appSettings.API.CLIENTI}/infosalone`, body);
    }

    getBookingInfo(id: number) {
        return this.http.get<InfoSalone>(`${appSettings.API.BOOKING}/infosalone/${id}`);
    }

    register(registerInfo: RegistrationUserInfo) {
        return this.http
            .post<AuthResponseData>(
                appSettings.API.REGISTER,
                registerInfo
            );
    }

    registerCollaboratore(registrationInfo: RegistrationCollaboratoreInfo) {
        return this.http.post(`${appSettings.API.REGISTER}collaboratore`, registrationInfo
        );
    }

    login(email: string, password: string, remember: boolean) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('grant_type', 'password');
        urlSearchParams.set('username', email);
        urlSearchParams.set('password', password);

        const body = urlSearchParams.toString();

        return this.http
            .post<AuthResponseData>(
                appSettings.API.TOKEN,
                body,
                {
                    headers: httpHeaders
                }
            ).pipe(
                switchMap(user => {
                    if (remember)
                        return from(this.storageService.set(StorageKeys.user, JSON.stringify(user)));
                    return of(sessionStorage.setItem(StorageKeys.user, JSON.stringify(user)));
                }),
                switchMap(() => this.getCurrentUserInfo())
            );
    }

    changePassword(changePasswordInfo: ChangePasswordInfo) {
        return this.http.post(appSettings.API.CHANGEPWD, changePasswordInfo);
    }

    forgotPassword(email: any) {
        return this.http.post(appSettings.API.FORGOTPWD, email);
    }

    resetPassword(resetInfo: ResetPasswordInfo) {
        return this.http.post(appSettings.API.RESETPWD, resetInfo);
    }

    async logout() {
        sessionStorage.removeItem(StorageKeys.user);
        this.setCurrentUserInfo(null);
        this.user = null;
        this.user = null;
        this._userPromise = null;
        await this.storageService.remove(StorageKeys.user);
    }

    sendInfo(Token: string = null) {
        const LastDeviceType = this.platform.platforms().join(', ')
        const LastAppVersion = environment.appVersion;
        const Device = LastDeviceType;
        return this.http.post(appSettings.API.ACCESS_INFO,
            {LastDeviceType, LastAppVersion, Token, App: 'salonappbusiness', Device}
        );
    }

    getChat(id: number) {
        return this.http.get<Chat[]>(`${appSettings.API.API}/chat/GetAll/${id}`)
    }

    sendChat(message: { idUserTo: number; idUserFrom: number; message: string }) {
        return this.http.post(`${appSettings.API.API}/chat`,
            message
        );
    }

    sendResetApps() {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = (today.getMonth() + 1).toString(); // getMonth() restituisce un valore da 0 (gennaio) a 11 (dicembre), quindi aggiungiamo 1
        if (month.length === 1) {
            month = '0' + month; // Aggiunge uno zero davanti se è necessario
        }
        let day = today.getDate().toString(); // Usa getDate() anziché getDay()
        if (day.length === 1) {
            day = '0' + day; // Aggiunge uno zero davanti se è necessario
        }

        const formattedDate = year + month + day; // Combina le stringhe

        return this.http.delete(`${appSettings.API.API}/booking/delete/${formattedDate}`);
    }

    sendResetFiches() {
        return this.http.delete(`${appSettings.API.API}/preconti/ResetAccount()`);
    }

    sendDeleteAccount(idClient: string | number) {
        return this.http.delete(`${appSettings.API.API}//clienti/Registrations/${idClient}`);
    }

    deleteChatItem(id: number) {
        return this.http.delete(`${appSettings.API.API}/chat/${id}`);
    }

    getPayments(ClientId: string | number) {
        return this.http.get<Payment[]>(`${appSettings.API.API}/payment/client/${ClientId}`)
    }

    createPayment(payment: Payment, isPureRequest: boolean = false): Observable<Payment> {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');

        return this.http.post<Payment>(`${appSettings.API.API}/payments`,
            payment,
            {headers: headers})
    }

    updatePayment(payment: Payment, isPureRequest: boolean = false) {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');
        return this.http.put(`${appSettings.API.API}/payments`,
            payment,
            {headers: headers});
    }

    deletePayment(payment: Payment, isPureRequest: boolean = false) {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');
        const id = payment.Id;
        return this.http.delete(`${appSettings.API.API}/payments/${id}`, {headers: headers})
    }


    // public currentUserInfo = new BehaviorSubject<UserProfileInfo>(null);

    getSupports(ClientId) {
        return this.http.get<Support[]>(`${appSettings.API.API}/support/client/${ClientId}`)
    }

    createSupport(support: Support, isPureRequest: boolean = false): Observable<Support> {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');

        return this.http.post<Support>(`${appSettings.API.API}/support`,
            support,
            {headers: headers})
    }

    updateSupport(support: Support, isPureRequest: boolean = false) {
        let headers = new HttpHeaders();
        support.SupportDate = UtilityHelper.toISOStringWithTimezone(support.SupportDate);
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');
        return this.http.put(`${appSettings.API.API}/support`,
            support,
            {headers: headers});
    }

    deleteSupport(support: Support, isPureRequest: boolean = false) {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');
        const id = support.Id;
        return this.http.delete(`${appSettings.API.API}/support/${id}`, {headers: headers})
    }

    abilitateDraft() {
        this.counter++;
        if (this.counter > 4) {
            this.toastService.presentToast('Servizio draft attivato');
            this.isDraft = true;
            this.http.patch(`${appSettings.API.API}/Clienti/${(this.currentUserInfo.id)}/flg-draft-main-serviceproduct/true`, null).subscribe();
            this.counter = 0;
        }
    }

    disableDraft() {
        this.counter++;
        if (this.counter > 4) {
            this.toastService.presentToast('Servizio draft disattivato');
            this.isDraft = false;
            this.http.patch(`${appSettings.API.API}/Clienti/${(this.currentUserInfo.id)}/flg-draft-main-serviceproduct/false`, null).subscribe();
            this.counter = 0;
        }
    }

    showId() {
        this.counterShowId++;
        if (this.counterShowId > 4) {
            this.toastService.presentToast('Id cliente: ' + this.currentUserInfo.id);
            this.counterShowId = 0;
        }
    }

    getMenuClientInfo(clientId: number) {
        return firstValueFrom(this.http.get<IMenuClientInfo>(appSettings.APISALONMENU.CLIENTI + `/client-info/${clientId}`));
    }

    putProfile(currentUser: UserProfileInfo) {
        const body = JSON.parse(JSON.stringify(currentUser)) as UserProfileInfo;
        if (body.Orari) {
            body.Orari = JSON.stringify(body.Orari);
        }
        return this.http.put<UserProfileInfo>(appSettings.API.CLIENTI, body);
    }

    getLanguages() {
        return this.http.get<Language[]>(
            appSettings.API.CLIENTI + `/lingue`
        ).pipe(
            tap(resp => this.languages = resp)
        );
    }

    sendQrCodeEmail(base64: string) {
        return this.http.post<ISection[]>(appSettings.API.API + '/QrCode/send-email', {
            Base64: base64
        });
    }

    getTemplateOptionsOfClient(clientId: number) {
        return this.http.get<PageTemplateOption[]>(
            appSettings.API.ADMIN + `/client/${clientId}/template-options`
        );
    }

    changeTemplateChoiceSalonMenu(clientId: number, newTemplateChoiceId: number) {
        return this.http.post(
            appSettings.API.ADMIN + `/client/${clientId}/${newTemplateChoiceId}`,
            {}
        );
    }

    private setCurrentUserInfo(user: UserProfileInfo) {
        this.currentUserInfo = user;
        this._currentUserInfo$.next(user);
    }
}

export interface AuthResponseData {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    userName: string;
    firstName: string;
    lastName: string;
    roles: string[];
    '.issued': Date;
    '.expires': Date;
    licenseExpire: Date;
}
