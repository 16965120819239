import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage-angular';
import {AppRoutingModule} from './app-routing.module';
import {Router} from '@angular/router';
import {AlertController, IonicModule, LoadingController, Platform} from '@ionic/angular';
import {Drivers} from '@ionic/storage';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';

import {LoadingService} from './services/loading.service';
import {ToastService} from './services/toast.service';
import {ValidationHelper} from './helpers/validation.helper';

import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import {InterceptorService} from './services/interceptor.service';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {ErrorManagerService} from './services/error-manager.service';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {FirebaseMessaging} from '@awesome-cordova-plugins/firebase-messaging/ngx';
import {QRCodeModule} from 'angularx-qrcode';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StorageService} from './services/storage.service';
import {UsersService} from './services/users.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgChartsModule} from 'ng2-charts';
import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {PaymentsComponent} from './modals/payments/payments.component';
import {SupportComponent} from './containers/support/support.component';
import {AppService} from './services/app.service';
import {ClientComponent} from './containers/client/client.component';

registerLocaleData(localeIt, 'it', localeItExtra);

export function startupStorage(storageService: StorageService): () => void {
    return () => storageService.init();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PaymentsComponent,
        SupportComponent,
        ClientComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '_salonappDB',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        //IonicSelectableModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWithDelay:7000'
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
        NgxGoogleAnalyticsRouterModule,
        QRCodeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgChartsModule
    ],
    providers: [
        HTTP,
        LoadingService,
        ToastService,
        StatusBar,
        FirebaseMessaging,
        ScreenOrientation,
        ValidationHelper,
        {provide: LOCALE_ID, useValue: 'it'},
        // {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
            deps: [ToastService, LoadingController, Router, Platform, AlertController, StorageService, UsersService, AppService]
        },
        {provide: ErrorHandler, useClass: ErrorManagerService},

        {
            provide: APP_INITIALIZER,
            useFactory: startupStorage,
            deps: [StorageService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
