import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

    constructor(private toastCtrl: ToastController) { }

    presentToast(message: string) {
        const toast = this.toastCtrl.create({
            duration: 3000,
            position: 'bottom',
            message,
            color: 'primary'
        });

        toast.then(toastRef => toastRef.present());
    }

    presentError(error: any) {
        this.presentToast(this.resolveError(error));
    }

    resolveError(error: any) {
        let errorMessage = '';

        try {
            if (error instanceof Error) {
                errorMessage = error.message;
            } else if (error.error?.Message) {
                errorMessage = error.error.Message;
            } else if (error.error?.error === 'invalid_grant') {
                errorMessage = error.error.error_description;
            } else if (error instanceof HttpErrorResponse) {
                if(error.error?.message)
                    errorMessage = error.error.message;
                else
                    errorMessage = error.error;
            } else if (error.message) {
                errorMessage = error.message;
            } else if (error.Message) {
                errorMessage = error.Message;
            } else if (error && error.error && typeof (error.error) === 'string') {
                errorMessage = error.error;
            } else if (error && error && typeof (error) === 'string') {
                errorMessage = error;
            } else if (error && error.status && error.status == '404') {
                errorMessage = 'La risorsa desiderata non è stata trovata!';
            } else if (error && error.error && error.error.Message) {
                errorMessage = error.error.Message;
            } else if (error && error.error && error.error.ModelState && error.error.ModelState[''] && error.error.ModelState[''][0]) {
                errorMessage = error.error.ModelState[''][0];
            } else if (error && error.error && error.error.error_description) {
                errorMessage = error.error.error_description;
            } else if (error.error.statusText) {
                errorMessage = error.error.statusText;
            } else {
                if (error.name == 'HttpErrorResponse') {
                    errorMessage = 'Errore di comunicazione. Connessione limitata o assente!';
                } else {
                    errorMessage = 'Errore!';
                }
            }

            return errorMessage.substring(0, 500);
        } catch(ex) {
            console.error(ex);
            return 'Errore sconosciuto!';
        }
    }
}
