import {PermissionCode} from '../enums/permission-code.enum';

export class Permissions {
    constructor(codes: PermissionCode[]) {
        if (codes.length === 0) return;

        this._anagrafica = codes.includes(PermissionCode.Anagrafica);
        this._cashRegister = codes.includes(PermissionCode.CashRegister);
        this._salonSettings = codes.includes(PermissionCode.SalonSettings);
        this._bookingSettings = codes.includes(PermissionCode.BookingSettings);
        this._targets = codes.includes(PermissionCode.Targets);
        this._warehouse = codes.includes(PermissionCode.Warehouse);
        this._expenses = codes.includes(PermissionCode.Expenses);
        this._services = codes.includes(PermissionCode.Services);
        this._collaborators = codes.includes(PermissionCode.Collaborators);
        this._clients = codes.includes(PermissionCode.Clients);
        this._support = codes.includes(PermissionCode.Support);
        this._promotions = codes.includes(PermissionCode.Promotions);
        this._whatsAppSettings = codes.includes(PermissionCode.WhatsAppSettings);
        this._whatsAppSettingsAdmin = codes.includes(PermissionCode.WhatsAppSettingsAdmin);
    }

    /**
     * @remarks
     * Profile Permission
     * @returns true if user has the permission otherwise false
     */
    get profile() {
        return this._anagrafica
            || this.cashRegister
            || this.salonSettings
            || this.bookingSettings
            || this.targets
            || this.whatsAppSettings
            || this.whatsAppSettingsAdmin;
    }

    // ######## Base Permissions ##########################################
    private _cashRegister = false;

    /**
     * @remarks
     * Cash Register Permission
     * @returns true if user has the permission otherwise false
     */
    get cashRegister() {
        return this._cashRegister;
    }

    private _collaborators = false;

    /**
     * @remarks
     * Collaborator Permission
     * @returns true if user has the permission otherwise false
     */
    get collaborators() {
        return this._collaborators;
    }

    private _services = false;

    /**
     * @remarks
     * Services Permission
     * @returns true if user has the permission otherwise false
     */
    get services() {
        return this._services;
    }

    private _warehouse = false;

    /**
     * @remarks
     * Warehouse Permission
     * @returns true if user has the permission otherwise false
     */
    get warehouse() {
        return this._warehouse;
    }

    private _expenses = false;

    /**
     * @remarks
     * Expenses Permission
     * @returns true if user has the permission otherwise false
     */
    get expenses() {
        return this._expenses;
    }

    private _anagrafica = false;

    /**
     * @remarks
     * Anagrafica Permission
     * @returns true if user has the permission otherwise false
     */
    get anagrafica() {
        return this._anagrafica;
    }

    private _salonSettings = false;

    /**
     * @remarks
     * Salon Settings Permission
     * @returns true if user has the permission otherwise false
     */
    get salonSettings() {
        return this._salonSettings;
    }

    private _bookingSettings = false;

    /**
     * @remarks
     * Booking Settings Permission
     * @returns true if user has the permission otherwise false
     */
    get bookingSettings() {
        return this._bookingSettings;
    }

    private _targets = false;

    /**
     * @remarks
     * Targets Permission
     * @returns true if user has the permission otherwise false
     */
    get targets() {
        return this._targets;
    }

    private _clients = false;

    /**
     * @remarks
     * Clients Permission
     * @returns true if user has the permission otherwise false
     */
    get clients() {
        return this._clients;
    }

    private _support = false;

    /**
     * @remarks
     * Support Permission
     * @returns true if user has the permission otherwise false
     */
    get support() {
        return this._support;
    }

    private _promotions = false;

    /**
     * @remarks
     * Promotions Permission
     * @returns true if user has the permission otherwise false
     */
    get promotions() {
        return this._promotions;
    }

    private _whatsAppSettings = false;

    /**
     * @remarks
     * WhatsApp Settings Permission
     * @returns true if user has the permission otherwise false
     */
    get whatsAppSettings() {
        return this._whatsAppSettings;
    }

    private _whatsAppSettingsAdmin = false;

    /**
     * @remarks
     * WhatsApp Settings Permission
     * @returns true if user has the permission otherwise false
     */
    get whatsAppSettingsAdmin() {
        return this._whatsAppSettingsAdmin;
    }
}
