import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageKeys, StorageService} from './storage.service';
import {UsersService} from './users.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(
        private _storageService: StorageService,
        private _usersService: UsersService,
    ) {
    }

    private _blockingPaymentError$ = new BehaviorSubject<string>(null);

    get blockingPaymentError$() {
        return this._blockingPaymentError$.asObservable();
    }

    public setBlockingPaymentError(message: string) {
        this._blockingPaymentError$.next(message);
    }

    public async logOut(router: Router = null) {
        await this._storageService.remove(StorageKeys.username);
        await this._storageService.remove(StorageKeys.pwd);
        await this._storageService.remove(StorageKeys.autoLogin);
        await this._storageService.remove(StorageKeys.user);

        sessionStorage.removeItem(StorageKeys.user);

        await this._usersService.logout();

        if (router)
            await router.navigateByUrl('/login');
    }

}
