import {fetchAndActivate, getRemoteConfig, getString, RemoteConfig} from 'firebase/remote-config';
import {environment} from '../environments/environment';

export class Configuration {
    static initialized = false;
    private static remoteConfig: RemoteConfig;
    private static overwrites: { [key: string]: string | number | boolean } = {};

    constructor() {
    }

    static async init() {
        Configuration.remoteConfig = getRemoteConfig();
        Configuration.remoteConfig.settings.minimumFetchIntervalMillis = 1000;
        Configuration.remoteConfig.defaultConfig = environment as any as { [key: string]: string | number | boolean };

        await fetchAndActivate(Configuration.remoteConfig);

        Configuration.initialized = true;
    }

    static overwriteValue(key: string, value: string | number | boolean) {
        Configuration.overwrites[key] = value;
    }

    static getString(key: string): string {
        if (!Configuration.remoteConfig) {
            return environment[key];
        }
        return Configuration.overwrites[key] as string || getString(Configuration.remoteConfig, key + '_' + environment.bundleName);
    }

    static getNumber(key: string): number {
        if (!Configuration.remoteConfig) {
            return environment[key];
        }
        return Configuration.overwrites[key] as number || Number(getString(Configuration.remoteConfig, key + '_' + environment.bundleName));
    }

    static getBoolean(key: string): boolean {
        if (!Configuration.remoteConfig) {
            return environment[key];
        }
        return Configuration.overwrites[key] as boolean || getString(Configuration.remoteConfig, key + '_' + environment.bundleName) === 'true';
    }

    static getObject(key: string): any {
        if (!Configuration.remoteConfig) {
            return environment[key];
        }
        return Configuration.overwrites[key] as any || JSON.parse(getString(Configuration.remoteConfig, key + '_' + environment.bundleName));
    }
}
