import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UsersService} from './services/users.service';

@Injectable({
    providedIn: 'root'
})
export class AppAlreadyAuthGuard implements CanActivate {
    usersService = inject(UsersService)
    router = inject(Router)

    constructor() {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        try {
            const user = await this.usersService.userPromise;
            if (user)
                return this.router.parseUrl('/home');
        } catch (error) {
            console.log(error);
        }
        return true;
    }
}
