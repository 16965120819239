import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AppAlreadyAuthGuard} from './app-already-auth.guard';
import {ConfigGuard} from './guards/config.guard';
import {environment} from 'src/environments/environment';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
        canActivate: [AppAlreadyAuthGuard, ConfigGuard]
    },
    {
        path: 'register',
        loadChildren: () => import('./auth/registrazione/registrazione.module').then(m => m.RegistrazionePageModule),
        canActivate: [ConfigGuard]
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
        canActivate: [ConfigGuard]
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [ConfigGuard]
    },
    {
        path: 'tabs',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsModule),
        canActivate: [ConfigGuard]
    },
    {
        path: 'clienti',
        loadChildren: () => import('./components/clienti/clienti.module').then(m => m.ClientiPageModule),
        canActivate: [ConfigGuard]
    },
    {
        path: 'collaboratori',
        loadChildren: () => import('./pages/collaboratori/collaboratori.module').then(m => m.CollaboratoriPageModule),
    },
    {
        path: 'listino',
        loadChildren: () => import('./pages/listino/listino.module').then(m => m.ListinoPageModule),
    },
    {
        path: 'magazzino',
        loadChildren: () => import('./pages/magazzino/magazzino.module').then(m => m.MagazzinoModule),
    },
    {
        path: 'promozioni',
        loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule),
    },
    {
        path: 'expenses',
        loadChildren: () => import('./pages/expenses/expenses.module').then(m => m.ExpensesModule),
    },
    {
        path: 'tenants',
        loadChildren: () => import('./pages/tenants/tenants.module').then(m => m.TenantsModule),
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    },
    {
        path: 'tos',
        loadChildren: () => import('./pages/tos/tos.module').then(m => m.TosModule),
    },

    {path: '**', redirectTo: 'tabs/' + environment.bottomTabs[0]},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
