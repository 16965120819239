import packageJson from '../../package.json';

export const environment = {
    appVersion: packageJson.version,
    production: false,
    smsGateway: '6Aq7',
    smsUser: 'digitalev',
    smsPassword: '6Aq7Ob/t47M',
    separatorChips: '#;',
    wsApiUrl: 'https://salonapp-test.osb.solutions/Ws',
    wsApiUrlSalonMenu: 'https://salonmenu-test.osb.solutions/Ws',
    hostName: 'https://apisalonapp.osb.solutions/',
    pageItemForsList: 10,
    googleAnalyticsKey: '',
    paginationLength: 50,
    appName: 'Lisapp',
    bundleName: 'lisapp',
    bundleDomain: '.digitalevolutionitalia.it',

    serviceDescriptionMaxCharacter: 200,
    serviceMaxCharacter: 40,
    pageItemForList: 50,
    
    firebaseConfig: {
        apiKey: 'AIzaSyB7X2NHKfISddzYTOt4mb935W0wZYdRXus',
        authDomain: 'booking-online-208b6.firebaseapp.com',
        projectId: 'booking-online-208b6',
        storageBucket: 'booking-online-208b6.appspot.com',
        messagingSenderId: '405410337723',
        appId: '1:405410337723:web:37b09f14c209b48c1eaa9e',
        measurementId: 'G-PFHSV5C9M9'
      },
    bottomTabs: [
        'calendario',
        'rubrica',
        'fiches',
        'reports',
        'appointments',
    ],
    topTabs: [
        'template-settings',
        'salon-profile'
    ],
   navigationCards: [
    ]

    // TODO: download the google-services.json and GoogleService-Info.plist from the firebase console
};
