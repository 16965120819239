import {Injectable} from '@angular/core';
import {Configuration} from '../configuration';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient) {
    }

    sendNotification(notification: { notification: { title: string; body: string }; data: { title: string; body: string }; to: string[] }) {
        return this.http.post(
            `${Configuration.getString('wsApiUrl')}/api/account/SendPushNotification`,
            notification
        );
    }
}
