import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { registerLicense } from '@syncfusion/ej2-base';

if (environment.production) {
  enableProdMode();
}

registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxdcHVRQ2ZeUEx3WUA=');

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      console.log('Registering new ServiceWork version...');
      navigator.serviceWorker
        .register('ngsw-worker.js')
        .then(() => console.log('ServiceWork registered!'));
    }
  })
  .catch(err => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

window.addEventListener("resize", () => {
    setInnerHeightCssVar();
})

const setInnerHeightCssVar = () => {
    document.documentElement.style.setProperty('--windowInnerHeight', `${window.innerHeight}px`);
}

setInnerHeightCssVar();

window['SKIP_PRINT'] = false;
