import {Injectable} from '@angular/core';
import {PermissionCode} from '../enums/permission-code.enum';
import {Permissions} from '../models/permissions.model';


@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    constructor() {
    }

    private _permissions = new Permissions([]);

    /**
     * @remarks
     * Permissions for current user
     * @returns an object that contains the permissions for the current user
     */
    get permissions() {
        return this._permissions;
    }

    setPermissions(permissionCodes: PermissionCode[]) {
        this._permissions = new Permissions(permissionCodes);
    }
}
