import {Injectable} from '@angular/core';
import {CanActivate, CanLoad} from '@angular/router';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class ConfigGuard implements CanActivate, CanLoad {
    constructor() {
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkConfig();
    }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkConfig();
    }

    private async checkConfig(): Promise<boolean> {
        if (!Configuration.initialized) {
            try {
                await Configuration.init();
            } catch (error) {
                console.error('Failed to load remote config', error);
                return false;
            }
        }
        return true;
    }
}
