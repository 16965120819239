import {UsersService} from '../../../services/users.service';
import {ServiziCliente} from '../../../models/servizio-cliente.model';
import {CategoriaCliente} from '../../../models/categoria-cliente.model';
import {Utility} from '../../../helpers/Utility';

export class TextActionCategory {
    action: TextAction;
    services: TextAction[];

    constructor(categoryAction: TextAction) {
        this.action = categoryAction;
    }
}

export class TextAction {
    private static GLOBAL_ID = -1000000000;
    private _pageWidth: number;

    constructor(
        public width = 150, public height = 50, public left = 0, public top = 0, public action: string = null,
        public link = '', public color = '', public photos: string[] = [], public pageSelected: string = null,
        public text = '', public text_de = '', public text_en = '',
        public text_es = '', public text_fr = '', public text_pt = '',
        public fontSize = 14, public fontColor = 'black',
        public fontAlign: 'left' | 'center' | 'right' = 'left', public fontStyle: 'oblique' | 'normal' | 'italic' = 'normal',
        public fontWeight: 'lighter' | 'bold' | 'normal' = 'normal',
        public lineHeight = 13, public letterSpacing = 1, public background = 'transparent',
        public widthPercent: number = null, public leftPercent: number = null,
        public heightPercent: number = null, public topPercent: number = null,
        public idService: number = null, public srcImage: number = null, public opacity = 1,
        public fontFamily = 'Comfortaa', public srcVideo = '', public imgText = '',
        public showText = 1, public name = '', public name_de = '', public name_en = '', public name_es = '',
        public name_fr = '', public name_pt = '', public images: string[] = [],
        public idCategory: number = null, public price: string = '',
        public screenOriginHeight = 720, public borderPX = 0, public borderColor = 'lightgray',
        public showAnimation = false, public isCreatedSede = false, public isLock = false,
        public IdPagesTemplate = '', public IdTextActionSede = null,
        public SyncType = 1, public Id = 0,
        public textModified = false,
        public deleteImages: string[] = [], public putImages: string[] = [],
        public deleteImagesGallery: string[] = [], public putImagesGallery: string[] = [],
        public show = false, public linkGalleryImages: string[] = [], public compressionImages: number[] = [],
        public multiTypeImages: boolean[] = [],
        public scaleFunc: (() => number) = () => 1,
        public serviceFunc: ((serviceId: number) => ServiziCliente) = () => null,
        public categoryFunc: ((categoryId: number) => CategoriaCliente) = () => null,
        public ParentCategoryId: number = null,
        public Order: number = 0,
        public BoxTypeId = 1,
        public isNew = false
    ) {
        if (this.Id === 0)
            this.Id = TextAction.GLOBAL_ID++;

        const pageDimentions = Utility.getConstantDimentions();
        this._pageWidth = pageDimentions.width;
        this._pageHeight = pageDimentions.height;

        if (this.widthPercent) {
            // this.width = this.calculatedWidth;
            this.widthPercent = null;
        }

        if (this.heightPercent) {
            // this.height = this.calculatedHeight;
            this.heightPercent = null;
        }

        if (this.topPercent) {
            // this.top = this.calculatedTop;
            this.topPercent = null;
        }

        if (this.leftPercent) {
            // this.left = this.calculatedLeft;
            this.leftPercent = null;
        }
    }

    private _pageHeight: number;

    // public get calculatedTop(){
    //     return this.topPercent ? ((this.topPercent * this._pageHeight) / 100): this.top;
    // }

    // public get calculatedLeft(){
    //     return this.leftPercent ? ((this.leftPercent * this._pageWidth) / 100): this.left;
    // }

    // public get calculatedHeight(){
    //     return this.heightPercent ? ((this.heightPercent * this._pageHeight) / 100): this.height;
    // }

    // public get calculatedWidth(){
    //     return this.widthPercent ? ((this.widthPercent * this._pageWidth) / 100): this.width;
    // }

    public get pageHeight() {
        return this._pageHeight;
    }

    public get calculatedTop() {
        return this.top;
    }

    public get calculatedLeft() {
        return this.left;
    }

    public get calculatedHeight() {
        return this.height;
    }

    public get calculatedWidth() {
        return this.width;
    }

    public get scaledTop() {
        return this.top * this.scaleFunc();
    }

    public get scaledLeft() {
        return this.left * this.scaleFunc();
    }

    public get scaledHeight() {
        return this.height * this.scaleFunc();
    }

    public get scaledWidth() {
        return this.width * this.scaleFunc();
    }

    public get type(): 'Text' | 'Image' | 'Video' {
        if (this.srcImage || (this.images && this.images.length > 0))
            return 'Image';

        if (this.srcVideo)
            return 'Video';

        return 'Text';
    }

    public get isImage() {
        return this.type === 'Image';
    }

    public get isText() {
        return this.type === 'Text';
    }

    public get isVideo() {
        return this.type === 'Video';
    }

    async getDivAction(imageWidth: number, imageHeight: number, storageAccount: string, usersService: UsersService) {
        // this.widthPercent = ((this.width * 100) / imageWidth);
        // this.leftPercent = ((this.left * 100) / imageWidth);
        // this.heightPercent = ((this.height * 100) / imageHeight);
        // this.topPercent = ((this.top * 100) / imageHeight);
        //this.screenOriginHeight = 720;
        const images = this.images.map(e => e.replace(storageAccount, '°§StorageAccount§°'));
        let img = '';
        if (this.srcVideo) {
            img = '    <iframe  \
                  src="' + this.srcVideo + '" style="height: 100%; width: 100%; left:0; position: absolute; z-index: 1" \
                  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" \
                  allowfullscreen> \
          </iframe>';
        } else if (this.srcImage !== null && this.images.length > 0) {
            const link = images[this.srcImage];
            if (link.includes('video--')) {

                img = '<iframe style="width:100%; height:100%;" src="' + link.replace('video--', '') + '"></iframe>';
            } else {
                let webpLink = link.replace('hidden', 'hidden/webp');
                webpLink = webpLink.replace('.jpeg', '.webp');
                webpLink = webpLink.replace('.png', '.webp');
                webpLink = '°§StorageAccount§°' + webpLink;

                const image = '°§StorageAccount§°' + images[this.srcImage];
                const srcSet = webpLink.includes('.webp') ? '<source srcset="' + webpLink + '" type="image/webp">' : '';
                img = '<picture>' +
                    srcSet +
                    '<img src="' + image + '" style="height: 100%; width:100%; position: absolute; left:0;top:0; z-index: 1">' +
                    '</picture>';
            }
        }

        let span = '';
        let textLang = this.text;
        const lang = this.getLanguage(usersService);
        if (lang !== 'it') {
            textLang = this['text_' + lang];
        }
        let text = '';
        if (textLang) {
            text = textLang.replace(/<br>/g, '');
            text = text.replace(/<p>/g, '<span>');
            text = text.replace(/<\/p>/g, '</span><br>');
            text = text.replace(/\r\n|\r|\n/g, '<br />');

            const styleFontSize = 'calc*(' + [this.screenOriginHeight, this.fontSize].join() + ')*;';
            const styleLetterSpacing = 'calcLetterSpacing*(' + [this.screenOriginHeight, this.letterSpacing].join() + ')*;';
            const styleLineHeight = 'calcLineHeight*(' + [this.screenOriginHeight, this.lineHeight].join() + ')*;';
            const stylePaddingTop = 'calcPaddingTop*(' + [this.screenOriginHeight, 8].join() + ')*;';
            span = '<span style="display:block; \
        z-index: 22; \
        color:' + this.fontColor + '; \
        position: relative; \
        left:0;top:0;width:100%;\
        font-size:' + this.fontSize + 'px;\
        letter-spacing:' + this.letterSpacing + 'px;\
        line-height:' + this.lineHeight + 'px;\
        padding-top: 8px;\
        ">' + text;
            if (this.price) {
                span += '<span style="float: right">' + this.price + '</span>';
            }
            span += '</span>';
        }
        let styleBorder = '';
        if (this.borderPX > 0) {
            styleBorder = `border: ${this.borderPX}px solid ${this.borderColor};`;
        }
        let classText = 'class="';
        if (this.showAnimation) {
            classText += 'showAnimation';
        }
        if (!img && !span && this.background?.includes('#000')) {
            classText += ' dark-white';
        }
        ``

        classText += '"';
        return '<div style="position: absolute; width:' + this.width + 'px; \
      left:' + this.left + 'px;top:' + this.top + 'px; \
      height:' + this.height + 'px; \
      color:' + this.fontColor + '; \
      background:' + this.background + '; \
      opacity:' + this.opacity + '; \
      font-weight:' + this.fontWeight + '; \
      font-style:' + this.fontStyle + '; \
      font-size:' + this.fontSize + 'px; \
      font-family:' + this.fontFamily + '; \
      line-height:' + this.lineHeight + 'px; \
      letter-spacing:' + this.letterSpacing + 'px; \
      z-index: ' + (this.srcImage !== null || !text ? 1 : 22) + '; ' + styleBorder + ' \
      text-align:' + this.fontAlign + ';" \
      ' + classText + '> ' + img + span + '\
      </div>';
    }

    adjustPercent(heightWidthFlip: { width: number; height: number }) {
        if (this.widthPercent) {
            this.width = ((this.widthPercent * heightWidthFlip.width) / 100);
            this.left = ((this.leftPercent * heightWidthFlip.width) / 100);
            this.height = ((this.heightPercent * heightWidthFlip.height) / 100);
            this.top = ((this.topPercent * heightWidthFlip.height) / 100);
        }
    }

    getLanguage(usersService: UsersService) {
        return usersService.currentLanguage.Nome.toLowerCase();
    }
}

export enum ActionText {
    link = 'l',
    page = 'p',
    gallery = 'g',
    galleryService = 'gs',
    galleryListino = 'gl',
    form = 'f',
}
