import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {UsersService} from './users.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorManagerService implements ErrorHandler {

  constructor(private injector: Injector) { }

    handleError(error: {code: string, message: string, stack: string}): void {
      if(!error.code ||  error.code !== '100') {
          // @ts-ignore
          if (error !== 'ATTENZIONE!! L\'app non è aggiornata all\'ultima versione, si prega di aggiornare. In caso di problemi contattare il supporto tecnico.' && error !== 'User name o password non sono corretti!') {
              console.error(error);
              if(!window.location.href.includes('localhost:8100')) {
                  const service: UsersService = this.injector.get(UsersService);
                  if (service.getCurrentUser && service.getCurrentUser.SendError) {
                      // while (errorLog.includes('/')) {
                      //     errorLog = errorLog.replace('/', '|');
                      // }
                      service.sendLog(error.toString()).subscribe()
                  }
              }
          }
      }
    }
}
