import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
    @Input() currentCliente;
}
