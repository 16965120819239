export enum PermissionCode {
    Anagrafica = 3000,
    CashRegister = 4000,
    SalonSettings = 5000,
    BookingSettings = 6000,
    Targets = 7000,
    Warehouse = 8000,
    Expenses = 8500,
    Services = 9000,
    Collaborators = 10000,
    Clients = 11000,
    Support = 12000,
    Promotions = 13000,
    WhatsAppSettings = 14000,
    WhatsAppSettingsAdmin = 15000
}
