import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { ModalController } from '@ionic/angular';
import { Support } from 'src/app/pages/clienti/cliente-detail/supporto/support';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {

  newSalonAppSupport: Support;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
    // TAVOLETTI: commentato perché manda il runtime in errore per style of undefined e sembra funzionare senza
    // (document.querySelector('.e-schedule-dialog-container') as HTMLElement).style.zIndex = '1';
}

print(name: string) {

    const doc = new jsPDF('p', 'px', 'a2');
    const stringHtml = document.getElementById('elementHtml');
    // html2canvas(stringHtml, {scale: 2}).then((result) => {
        doc.html(stringHtml.innerHTML, {width: 800, windowWidth: 800}).then(resp => {
            doc.save('schedacliente-' + name + '_' + new Date().toISOString() + '.pdf');
        });
    // });
}

}
