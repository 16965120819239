import {Configuration} from './configuration';

export const appSettings = {
    Configuration: {
        APP_TITLE: 'Agenda',
        EVENTS_REFRESH: 40000
    },
    API: {
        TOKEN: `${Configuration.getString('wsApiUrl')}/Token`,
        GALLERY: `${Configuration.getString('wsApiUrl')}/api/gallery`,
        API: `${Configuration.getString('wsApiUrl')}/api`,
        PARSER: `${Configuration.getString('wsApiUrl')}/api/parser`,
        PARSERMENU: `${Configuration.getString('wsApiUrl')}/api/menu/parser`,
        CONFIG: `${Configuration.getString('wsApiUrl')}/api/config`,
        ACCESS_INFO: `${Configuration.getString('wsApiUrl')}/api/account/AccessInfo`,
        REGISTER: `${Configuration.getString('wsApiUrl')}/api/account/register`,
        CHANGEPWD: `${Configuration.getString('wsApiUrl')}/api/account/changepassword`,
        FORGOTPWD: `${Configuration.getString('wsApiUrl')}/api/account/forgotpassword`,
        RESETPWD: `${Configuration.getString('wsApiUrl')}/api/account/resetpassword`,
        CHECKACTIVE: `${Configuration.getString('wsApiUrl')}/api/account/checkactive`,
        CHANGEPROFILE: `${Configuration.getString('wsApiUrl')}/api/account/changeuserprofile`,
        AGENDA: `${Configuration.getString('wsApiUrl')}/api/agenda`,
        NOTES: `${Configuration.getString('wsApiUrl')}/api/agenda/notes`,
        CLIENTI: `${Configuration.getString('wsApiUrl')}/api/clienti`,
        SPONSORS: `${Configuration.getString('wsApiUrl')}/api/sponsors`,
        ADMIN: `${Configuration.getString('wsApiUrl')}/api/admin`,
        COLLABORATORI: `${Configuration.getString('wsApiUrl')}/api/collaboratori`,
        CATEGORIE: `${Configuration.getString('wsApiUrl')}/api/categoriecliente`,
        CATEGORIE_MENU: `${Configuration.getString('wsApiUrl')}/api/CategorieClienteMenu`,
        BRANDPRODOTTI: `${Configuration.getString('wsApiUrl')}/api/BrandProdotti`,
        CATEGORIEPRODOTTI: `${Configuration.getString('wsApiUrl')}/api/CategorieProdotti`,
        SERVIZI: `${Configuration.getString('wsApiUrl')}/api/servizicliente`,
        SERVIZI_MENU: `${Configuration.getString('wsApiUrl')}/api/ServiziClienteMenu`,
        ORDINI: `${Configuration.getString('wsApiUrl')}/api/ordini`,
        REPORTS: `${Configuration.getString('wsApiUrl')}/api/reports`,
        REPORTSMENU: `${Configuration.getString('wsApiUrl')}/api/menu/report`,
        FICHES: `${Configuration.getString('wsApiUrl')}/api/preconti`,
        MAGAZZINO: `${Configuration.getString('wsApiUrl')}/api/magazzino`,
        MAGAZZINO_COMPLETE: `${Configuration.getString('wsApiUrl')}/api/magazzino/warehousecomplete`,
        BOOKING: `${Configuration.getString('wsApiUrl')}/api/booking`,
        CUSTOMERS: `${Configuration.getString('wsApiUrl')}/api/customers`,
        PROMOTIONS: `${Configuration.getString('wsApiUrl')}/api/promotions`,
        TENANTS: `${Configuration.getString('wsApiUrl')}/api/tenants`,
        PROMOTIONTYPES: `${Configuration.getString('wsApiUrl')}/api/promotiontypes`,
        EXPENSES: `${Configuration.getString('wsApiUrl')}/api/expenses`,
        UPLOAD: `${Configuration.getString('wsApiUrl')}/api/upload`,
        NOTIFICATION: `${Configuration.getString('wsApiUrl')}/api/Notification`,
        PAGE_TEMPLATE_CONFIG: `${Configuration.getString('wsApiUrl')}/api/PagesTemplateConfig`,
        MENUS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/Menus`,
        PAGE_IMAGE: `${Configuration.getString('wsApiUrl')}/api/PageImage`,
        PAGE_SERVIZIO_TEMPLATE: `${Configuration.getString('wsApiUrl')}/api/PageServizioTemplate`,
        PAGE_TEMPLATE: `${Configuration.getString('wsApiUrl')}/api/PageTemplate`,
        TEMPLATE: `${Configuration.getString('wsApiUrl')}/api/Template`,
        MENU_TEMPLATES: `${Configuration.getString('wsApiUrl')}/api/MenuTemplates`,
        CATALOGO: `${Configuration.getString('wsApiUrl')}/api/catalogo`,
        CATALOG: `${Configuration.getString('wsApiUrl')}/api/catalog`,
    },
    APISALONMENU: {
        TOKEN: `${Configuration.getString('wsApiUrlSalonMenu')}/Token`,
        GALLERY: `${Configuration.getString('wsApiUrlSalonMenu')}/api/gallery`,
        API: `${Configuration.getString('wsApiUrlSalonMenu')}/api`,
        PARSER: `${Configuration.getString('wsApiUrlSalonMenu')}/api/parser`,
        PARSERMENU: `${Configuration.getString('wsApiUrlSalonMenu')}/api/menu/parser`,
        CONFIG: `${Configuration.getString('wsApiUrlSalonMenu')}/api/config`,
        ACCESS_INFO: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/AccessInfo`,
        REGISTER: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/register`,
        CHANGEPWD: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/changepassword`,
        FORGOTPWD: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/forgotpassword`,
        RESETPWD: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/resetpassword`,
        CHECKACTIVE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/checkactive`,
        CHANGEPROFILE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/account/changeuserprofile`,
        AGENDA: `${Configuration.getString('wsApiUrlSalonMenu')}/api/agenda`,
        NOTES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/agenda/notes`,
        CLIENTI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/clienti`,
        SPONSORS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/sponsors`,
        ADMIN: `${Configuration.getString('wsApiUrlSalonMenu')}/api/admin`,
        COLLABORATORI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/collaboratori`,
        CATEGORIE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/categoriecliente`,
        CATEGORIE_MENU: `${Configuration.getString('wsApiUrlSalonMenu')}/api/CategorieClienteMenu`,
        BRANDPRODOTTI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/BrandProdotti`,
        CATEGORIEPRODOTTI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/CategorieProdotti`,
        SERVIZI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/servizicliente`,
        SERVIZI_MENU: `${Configuration.getString('wsApiUrlSalonMenu')}/api/ServiziClienteMenu`,
        ORDINI: `${Configuration.getString('wsApiUrlSalonMenu')}/api/ordini`,
        REPORTS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/reports`,
        REPORTSMENU: `${Configuration.getString('wsApiUrlSalonMenu')}/api/menu/report`,
        FICHES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/preconti`,
        MAGAZZINO: `${Configuration.getString('wsApiUrlSalonMenu')}/api/magazzino`,
        MAGAZZINO_COMPLETE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/magazzino/warehousecomplete`,
        BOOKING: `${Configuration.getString('wsApiUrlSalonMenu')}/api/booking`,
        CUSTOMERS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/customers`,
        PROMOTIONS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/promotions`,
        TENANTS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/tenants`,
        PROMOTIONTYPES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/promotiontypes`,
        EXPENSES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/expenses`,
        UPLOAD: `${Configuration.getString('wsApiUrlSalonMenu')}/api/upload`,
        NOTIFICATION: `${Configuration.getString('wsApiUrlSalonMenu')}/api/Notification`,
        PAGE_TEMPLATE_CONFIG: `${Configuration.getString('wsApiUrlSalonMenu')}/api/PagesTemplateConfig`,
        MENUS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/Menus`,
        PAGE_IMAGE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/PageImage`,
        PAGE_SERVIZIO_TEMPLATE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/PageServizioTemplate`,
        PAGE_TEMPLATE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/PageTemplate`,
        TEMPLATE: `${Configuration.getString('wsApiUrlSalonMenu')}/api/Template`,
        MENU_TEMPLATES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/MenuTemplates`,
        CATALOGO: `${Configuration.getString('wsApiUrlSalonMenu')}/api/catalogo`,
        CATALOG: `${Configuration.getString('wsApiUrlSalonMenu')}/api/catalog`,
        SEMINAR_CATEGORIES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/pageTemplate/%s/categories`,
        SEMINAR_SUBCATEGORIES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/category/%s/subcategories`,
        SEMINAR_EVENTS: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/subcategory/%s/events`,
        SEMINAR_EVENT: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/%s`,
        SEMINAR_EVENT_DATES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/%s/dates`,
        SAVE_EVENT: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events`,
        ALL_CATEGORIES: `${Configuration.getString('wsApiUrlSalonMenu')}/api/events/pageTemplate/%s/summary`
    },
    DOCS: {
        GDPR_TEMPLATE: `${Configuration.getString('wsApiUrl')}/Templates/Docs/Privacy_Statement_Understanding_OSB_AGENDA.PDF`,
        TERMS_TEMPLATE: `${Configuration.getString('wsApiUrl')}/Templates/Docs/CONTRATTO_DI_CONCESSIONE_IN_USO_DEL_SERVIZIO_AGENDA.PDF`,
        COLL_TERMS_TEMPLATE: `${Configuration.getString('wsApiUrl')}/Templates/Docs/REGOLAMENTO COLLABORATORE.pdf`,
        AGENT_HELP: `${Configuration.getString('wsApiUrl')}/Templates/Docs/Presentazione Cliente.pdf`
    }
}
