import {Intestatario} from '../models/fiche.model';
import {ServiziCliente} from '../models/servizio-cliente.model';
import {ScheduleEventAppointment, ServizioItem} from '../models/appuntamenti.model';
import {ReportList} from '../models/reports.model';
import {BrandProdotti, CategoryProduct} from '../models/category-product.model';

export function nameof<T>(key: keyof T): keyof T {
    return key;
}

export class UtilityHelper {


    static getServicesApp(values: Intestatario[] | ServiziCliente[] = [], schedules: ScheduleEventAppointment[],
                          IdCustomer: number = null, tempServizi: ServizioItem[] = []): Intestatario[] {
        let ids = [];
        if (IdCustomer) {
            // È inserimento, quindi tolgo quelli già inseriti, e presenti sul calendario, per il cliente selezionato dal form
            schedules = schedules.filter(e => e.IdCustomer === IdCustomer);
        } else if (IdCustomer === null || IdCustomer === 0) {
            schedules = [];
        }
        if (tempServizi) {
            ids = tempServizi.map(e => e.IdServizio);
        }
        ids.push(...schedules.map(e => e.IdServizio));
        // @ts-ignore
        return values.filter(e => !ids.includes(e.Id));
    }

    static capitalizeFirstLetter(string) {
        if(!string)
            return;
        if (string.split(' ').length > 1) {
            const words = string.split(' ');
            return words.map(word => UtilityHelper.capitalizeFirstLetter(word)).join(' ');
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // static checkEventInDate(e: ScheduleEvent, date: Date): boolean {
    //     date.setHours(0, 0, 0, 0);
    //     const date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    //     const startDate = new Date(e.StartTime);
    //     return startDate.getTime() >= date.getTime() && startDate.getTime() <= date2.getTime()
    // }

    public static isToday(date: Date): boolean {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    public static getReports(idProfile: number): ReportList {
        return {
            items: [
                {
                    title: 'Report Salone e Collaboratori',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2'
                },
                {
                    title: 'Report Clienti',
                    subtitle: '',
                    avatar: 'assets/images/icons/Report-Logo-7.png',
                    button: 'Download',
                    rpt: 'rpt7'
                },
                {
                    title: 'Report Mensile',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio suddiviso nei mesi dell\'anno',
                    avatar: 'assets/images/icons/Report-Logo-3.png',
                    button: 'Download',
                    rpt: 'rpt3'
                },
                {
                    title: 'Report Giornaliero',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio suddiviso per giorno',
                    avatar: 'assets/images/icons/Report-Logo-4.png',
                    button: 'Download',
                    rpt: 'rpt4'
                },
                {
                    title: 'Report Magazzino',
                    subtitle: 'Analisi dei carichi e scarichi effettuati sul proprio magazzino prodotti',
                    avatar: 'assets/images/icons/barber.jpg',
                    button: 'Download',
                    rpt: 'magazzino'
                }
            ],
            items_adv: [
                {
                    title: 'Target Collaboratori',
                    subtitle: '',
                    avatar: 'assets/images/icons/Report-Logo-5.png',
                    button: 'Download',
                    rpt: 'rpt5',
                    profile: 2
                },
                {
                    title: 'Target Collaboratori',
                    subtitle: '',
                    avatar: 'assets/images/icons/Report-Logo-5.png',
                    button: 'Download',
                    rpt: 'rpt5',
                    profile: 5
                },
                {
                    title: 'Report Clienti',
                    subtitle: 'Analisi delle presenze e servizi effettuati dai clienti',
                    avatar: 'assets/images/icons/Report-Logo-6.png',
                    button: 'Download',
                    rpt: 'rpt6',
                    profile: 2
                },
                {
                    title: 'Report Clienti',
                    subtitle: 'Analisi delle presenze e servizi effettuati dai clienti',
                    avatar: 'assets/images/icons/Report-Logo-6.png',
                    button: 'Download',
                    rpt: 'rpt6',
                    profile: 5
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 1
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 3
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 7
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 8
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 4
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 5
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 6
                },
                {
                    title: 'Report Salone e Collaboratori Avanzato',
                    subtitle: 'Analisi del fatturato e quantità di ogni singolo servizio con focus sui collaboratori',
                    avatar: 'assets/images/icons/Report-Logo-2.png',
                    button: 'Download',
                    rpt: 'rpt2a',
                    profile: 9
                }
            ].filter(e => e.profile === idProfile)
        };
    }

    static getCategoriesCustomer() {
        return [{
            id: 0,
            value: 'all'
        }, {
            id: 1,
            value: 'calendar.today'
        }, {
            id: 2,
            value: 'customer.without_policy'
        }, {
            id: 3,
            value: 'customer.without_mail'
        }, {
            id: 4,
            value: 'customer.birthday'
        }, {
            id: 5,
            value: 'customer.deleted'
        }, {
            id: 7,
            value: 'customer.duplicated',
            subTypes: [
                {
                    id: 1,
                    value: 'phone'
                },
                {
                    id: 2,
                    value: 'Email'
                },
                // {
                //     id: 2,
                //     value: 'Nome e cognome'
                // }
            ]
        }];
    }

    static getBrandProdotti(): BrandProdotti[] {
        return [{
            Prodotti: [], Id: 5, Nome: 'COLOR', ImageUrl: null, Created: '2021-01-08T20:56:28.71', Modified: '2021-01-08T20:56:28.71',
            IsDeleted: false, Deleted: null
        }, {
            Prodotti: [], Id: 1, Nome: 'NIOXIN', ImageUrl: null, Created: '2020-09-15T12:43:11.233',
            Modified: '2020-09-15T12:43:11.233', IsDeleted: false, Deleted: null
        }, {
            Prodotti: [], Id: 2, Nome: 'ON HAIR',
            ImageUrl: null, Created: '2020-09-15T12:43:11.233', Modified: '2020-09-15T12:43:11.233', IsDeleted: false,
            Deleted: null
        }, {
            Prodotti: [], Id: 3, Nome: 'SEBASTIAN', ImageUrl: null, Created: '2020-09-15T12:43:11.233',
            Modified: '2020-09-15T12:43:11.233', IsDeleted: false, Deleted: null
        }, {
            Prodotti: [], Id: 4, Nome: 'SYSTEM PROFESSIONAL',
            ImageUrl: null, Created: '2020-09-15T12:43:11.233', Modified: '2020-09-15T12:43:11.233', IsDeleted: false, Deleted: null
        },
            {
                Prodotti: [], Id: 6, Nome: 'WELLA', ImageUrl: null, Created: '2021-01-08T20:56:28.71', Modified: '2021-01-08T20:56:28.71',
                IsDeleted: false, Deleted: null
            }];
    }

    static getCategorieProdotti(): CategoryProduct[] {
        return [{
            Id: 1,
            ImageUrl: null,
            IsDeleted: false,
            Modified: '2020-09-15T12:43:11.233',
            ModifiedBy: null,
            Nome: 'ACCESSORI E ATTREZZATURE ELETTRICHE'
        },
            {
                Id: 34,
                ImageUrl: null,
                IsDeleted: false,
                Modified: '2021-01-08T20:56:28.71',
                ModifiedBy: null,
                Nome: 'ALTRO'
            },
            {
                Id: 2,
                ImageUrl: null,
                IsDeleted: false,
                Modified: '2020-09-15T12:43:11.233',
                ModifiedBy: null,
                Nome: 'BALANCE ',
            },
            {
                Id: 35,
                ImageUrl: null,
                IsDeleted: false,
                Modified: '2021-01-08T20:56:28.71',
                ModifiedBy: null,
                Nome: 'BLONDOR'
            },
            {
                Id: 36,
                ImageUrl: null,
                IsDeleted: false,
                Modified: '2021-01-08T20:56:28.71',
                ModifiedBy: null,
                Nome: 'BOOSTER'
            },
            {
                Id: 3,
                ImageUrl: null,
                IsDeleted: false,
                Modified: '2020-09-15T12:43:11.233',
                ModifiedBy: null,
                Nome: 'BRAZIL CACAU'
            }
        ];
    }

    static getHoursFromValue(value: string): string {
        const date = new Date(value);
        let hours = date.getHours() + '';
        if (hours.length === 1) {
            hours = '0' + hours;
        }
        let minutes = date.getMinutes() + '';
        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }

    static toISOStringWithTimezone = date => {
        date = new Date(date);
        const tzOffset = -date.getTimezoneOffset();
        const diff = tzOffset >= 0 ? '+' : '-';
        const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            diff + pad(tzOffset / 60) +
            ':' + pad(tzOffset % 60);
    };
}
