import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {forkJoin, Observable, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ToastService} from './toast.service';
import {AlertController, Platform} from '@ionic/angular';
import {from} from 'rxjs/internal/observable/from';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {UsersService} from './users.service';
import {AppHelper} from '../app.helper';
import {AppService} from './app.service';
import {Configuration} from '../configuration';
import {inject} from '@angular/core';

export class InterceptorService implements HttpInterceptor {
    private showingError = false;
    private usersService = inject(UsersService);
    private toastService = inject(ToastService);
    private router = inject(Router);
    private platform = inject(Platform);
    private alertCtrl = inject(AlertController);
    private _appService = inject(AppService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = {};
        let url = req.url;
        if (this.usersService.urlForce) {
            url = url.replace(Configuration.getString('wsApiUrl'), this.usersService.urlForce);
        }

        const noAuthHeader = req.url.includes('blob.core.windows.net');

        return forkJoin(
            [
                from(this.usersService.checkCurrentUser())
            ]
        )
            .pipe(
                // tap((data) => (data[0]) ? data[0].present() : true),
                map((data) => {
                        if (!req.url.includes('Token') && !req.url.includes('service.cgi') && !req.url.includes('printer.htm') && (req.url.includes(environment.wsApiUrl) || req.url.includes(environment.wsApiUrlSalonMenu))) {
                            //TODO: TO CHECK MELVIN
                            //if (!req.url.includes('Token') && !req.url.includes('service.cgi') && req.url.includes(Configuration.getString('wsApiUrl'))) {
                            const LastAppVersion = environment.appVersion;
                            const LastDeviceType = this.platform.platforms().join(', ');
                            headers = {
                                LastAppVersion,
                                LastDeviceType
                            };

                            const userObj = this.usersService.user;
                            if (userObj && userObj.access_token) {
                                if (!noAuthHeader) {
                                    headers['Authorization'] = 'Bearer ' + userObj.access_token;
                                }
                            }

                            return req.clone({
                                url,
                                headers: new HttpHeaders(headers)
                            });
                        }

                        return req;
                    }
                ),
                switchMap((reqN) => next.handle(reqN).pipe(
                        catchError(err => {
                            if (this.isBlockingPaymentDateError(err)) {
                                this.handleBlockingPaymentDateError(err);
                                return throwError(() => err);
                            }

                            const keys = Object.keys(err);
                            for (const key of keys) {
                                // tslint:disable-next-line:no-console
                                console.error('[ERROR ' + key + ']', (err[key]));
                            }
                            const error = this.toastService.resolveError(err);
                            if (err.status && (err.status === 401 || err.status === 403)) {
                                this.router.navigateByUrl('/login');
                                if (err.status === 403 && !this.showingError) {
                                    this.showingError = true;
                                    this.alertCtrl.create({
                                        header: 'Attenzione',
                                        message: error,
                                        buttons: [{
                                            text: 'Clicca per aggiornare',
                                            cssClass: 'version-button default-button ion-text-capitalize ion-margin-horizontal box-shadow ion-margin-top md button button-block button-solid ion-activatable ion-focusable hydrated',
                                            handler: () => AppHelper.storeRedirectionHandler(this.platform)
                                        }]
                                    }).then(modal => modal.present());
                                }
                            } else if (err.status && (err.status === 500) && url.includes('currentUserInfo')) {
                                this.router.navigateByUrl('/login');
                            } else if (err.status && (err.status === 409)) {
                                this.showingError = true;
                                this.alertCtrl.create({
                                    header: 'Attenzione',
                                    message: 'La mail o il telefono inserito è gia associata ad un altro cliente, si prega di cambiarla'
                                }).then(modal => modal.present());
                            }
                            return throwError(() => error);
                        }),
                    )
                )
            );
    }

    private isBlockingPaymentDateError(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            return error.status === 403 && error.error === 'ATTENZIONE: riscontrati problemi con l’account si prega di contattare il supporto tecnico';
        }

        return false;
    }

    private async handleBlockingPaymentDateError(error: HttpErrorResponse) {
        this._appService.setBlockingPaymentError(error.error);
        await this._appService.logOut(this.router);
    }
}
