import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {appSettings} from '../app-settings';
import {User} from '../models/user.model';

export interface AuthResponseData {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    userName: string;
    firstName: string;
    lastName: string;
    roles: string[];
    '.issued': Date;
    '.expires': Date;
    licenseExpire: Date;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private _user = new BehaviorSubject<User>(null);
    private activeLogoutTimer: any;

    constructor(private http: HttpClient) {
    }

    get token() {
        return this._user.asObservable().pipe(
            map(user => {
                if (user) {
                    return user.token;
                } else {
                    return null;
                }
            })
        );
    }

    register(registerInfo: any) {
        return this.http
            .post<AuthResponseData>(
                appSettings.API.REGISTER,
                registerInfo
            );
    }

    login(email: string, password: string) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('grant_type', 'password');
        urlSearchParams.set('username', email);
        urlSearchParams.set('password', password);

        const body = urlSearchParams.toString();

        return this.http
            .post<AuthResponseData>(
                appSettings.API.TOKEN,
                body,
                {
                    headers: httpHeaders
                }
            )
            .pipe(tap(this.setUserData.bind(this)));
    }

    logout() {
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }
        this._user.next(null);
    }

    ngOnDestroy() {
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }
    }

    private autoLogout(duration: number) {
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }
        this.activeLogoutTimer = setTimeout(() => {
            this.logout();
        }, duration);
    }

    private setUserData(userData: AuthResponseData) {
        const expirationTime = new Date(
            new Date().getTime() + +userData.expires_in * 1000
        );
        const user = new User(
            userData.access_token,
            userData.refresh_token,
            userData.userName,
            userData.firstName,
            userData.lastName,
            userData.roles,
            expirationTime,
            userData.licenseExpire
        );


        this._user.next(user);
        this.autoLogout(user.tokenDuration);
    }
}
