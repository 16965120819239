import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {CategoriaClienteMenu} from '../models/categoria-cliente-menu.model';
import {WeeklyHour} from '../models/user-profile.model';
import {ServiziCliente} from '../models/servizio-cliente.model';
import {CategoriaCliente} from '../models/categoria-cliente.model';
import {TextAction} from '../pages/menu/text-action/text-action';
import {IMenuItemImageDto} from '../dto/model-item-image.dto';
import {IMenuPage} from '../models/menu-page';
import {PageTemplate} from '../models/page-template.model';
import {PagesListino} from '../models/PagesListino';
import {UsersService} from '../services/users.service';
import {Catalog} from '../components/catalogo/catalog';
import {IMenuTemplatePage} from '../models/menu-template-page';
import {TemplateService} from '../services/template.service';
import {PreviewPageComponent} from '../pages/menu-template/preview-page/preview-page.component';
import {TemplatePageEditModel} from '../models/template-page-edit.model';

export class Utility {

    static readonly languages = [
        {Id: 1, Nome: 'IT', NomeEsteso: 'Italiano'},
        {Id: 2, Nome: 'EN', NomeEsteso: 'English'},
        {Id: 3, Nome: 'ES', NomeEsteso: 'Español'},
        {Id: 4, Nome: 'PT', NomeEsteso: 'Português'},
        {Id: 5, Nome: 'FR', NomeEsteso: 'Français'},
        {Id: 6, Nome: 'DE', NomeEsteso: 'Deutsch'}
    ];

    static presetHour(collaboratorHour: WeeklyHour) {
        collaboratorHour.MorningFrom = '06:00';
        collaboratorHour.MorningTo = '12:00';
        collaboratorHour.EveningFrom = '13:00';
        collaboratorHour.EveningTo = '22:00';
    }

    static getModelHour(collaboratorHour: WeeklyHour, key: string): string {
        switch (key) {
            case 'MorningFrom':
                return '06:00'
            case 'MorningTo':
                return collaboratorHour.MorningFrom ? collaboratorHour.MorningFrom : null
            case 'EveningFrom':
                return collaboratorHour.MorningTo
            case 'EveningTo':
                return collaboratorHour.EveningFrom
        }
    }

    static getOffset(): number {
        const date = new Date();
        return -1 * date.getTimezoneOffset() / 60;
    }

    static getModelDate(bookingHourElement: string): Date {
        const date = new Date();
        const offset = -1 * date.getTimezoneOffset() / 60;
        if (bookingHourElement.includes(':')) {
            date.setHours(+bookingHourElement.split(':')[0] + offset, +bookingHourElement.split(':')[1]);
        }
        date.setHours(date.getHours());
        return date;
    }

    static dateFromHours(date: Date, hours: string): Date {
        date = new Date(date);
        const offset = -1 * date.getTimezoneOffset() / 60;
        if (hours.includes(':')) {
            date.setHours(+hours.split(':')[0] + offset, +hours.split(':')[1]);
        }
        date.setHours(date.getHours());
        return date;
    }

    static getHourFromEvent(time: string): string {
        const date1To = new Date(time);
        return Utility.getHourFromDate(date1To);
    }

    static getHourFromDate(date: Date): string {
        let hour = date.getHours().toString();
        if (hour.length < 2) {
            hour = '0' + hour;
        }
        let minutes = date.getMinutes().toString();
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        return hour + ':' + minutes;
    }

    static isSameDay(day: string, otherDay: string): boolean {
        const dayDate = new Date(day);
        const otherDate = new Date(otherDay);
        return dayDate.getDate() === otherDate.getDate() &&
            dayDate.getMonth() === otherDate.getMonth() &&
            dayDate.getFullYear() === otherDate.getFullYear();
    }


    static sortListinoMenu(categories: CategoriaClienteMenu[]) {
        categories.sort((a, b) => a.Nome.toLowerCase() > b.Nome.toLowerCase() ? 1 : -1);
        for (const cat of categories) {
            cat.ServiziCliente.sort((a, b) => a.Nome.toLowerCase() > b.Nome.toLowerCase() ? 1 : -1);
        }

        return categories;
    }

    static trimEnd(str: string, char: string) {
        while (str[str.length - 1] === char)
            str = str.substring(0, str.length - 1);

        return str;
    }

    static checkSaveToUrl(alertCtrl: AlertController, translate: TranslateService): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            alertCtrl.create({
                header: 'Alert',
                message: 'Salvare le modifiche?',
                backdropDismiss: false,
                buttons: [
                    {
                        text: translate.instant('dismiss'),
                        handler: () => {
                            resolve(null);
                        }
                    },
                    {
                        text: 'Non salvare',
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: 'Salva',
                        handler: () => {
                            resolve(true);
                        }
                    }]
            }).then(alert => alert.present());
        });
    }

    static checkSave(abilitateSave: boolean, alertCtrl: AlertController, translate: TranslateService): Observable<boolean> {
        return new Observable(observer => {
            if (abilitateSave) {
                alertCtrl.create({

                    header: 'Attention',
                    message: 'do you really want to proceed?',
                    buttons: [
                        {
                            text: translate.instant('dismiss'),
                            role: 'cancel',
                            handler: () => {
                                observer.next(false);
                            }
                        },
                        {
                            text: 'Si',
                            handler: () => {
                                observer.next(true);
                            }
                        }]
                }).then(alert => alert.present());
            } else {
                observer.next(true);
            }
        });
    }

    static getNewPage(resp, IdPageTemplate, TextActions: TextAction[], imageItems: IMenuItemImageDto[], pageName: string): IMenuPage {
        return {
            ClientId: null,
            MenuId: null,
            IsServiceDetailsPage: false,
            IsServicesPage: false,
            IsVisibleToCustomers: false,
            PageTypeId: 1,
            IsDeployedToClients: false,
            Id: null,
            HtmlPage: '',
            PageName: pageName,
            TextActions,
            ImageItems: imageItems,
            CategoryItems: [],
            TextItems: [],
            IdPageTemplate,
            isModified: true,
            IdPageSede: (resp) ? resp.IdPageSede : null,
            IndexFleep: '',
            IdServizio: null,
            Config: {
                Id: 0,
                ServicesContainerTop: 100,
                ServicesContainerLeft: 220,
                ServicesContainerWidth: 275,
                ServicesContainerHeight: null,
                ServicesContainerPadding: 0,
                ServicesContainerGap: 10,
                ServicesContainerBackgorundColor: null,
                IdPagesTemplate: null,
                FontAlignCategory: 'left',
                FontAlignService: 'left',
                FontColorCategory: '#000000',
                FontColorService: '#000000',
                FontFamilyCategory: 'Aspira',
                FontFamilyService: 'Aspira',
                FontSizeCategory: 18,
                FontSizeService: 17,
                FontStyleCategory: 'normal',
                FontStyleService: 'normal',
                FontWeightCategory: 'bold',
                FontWeightService: 'normal',
                HeightCategory: 30,
                HeightService: 20,
                LeftCategory: 220,
                LetterSpacingCategory: 0,
                LetterSpacingService: 0,
                LineHeightCategory: 18,
                LineHeightService: 13,
                LeftService: 220,
                TopCategory: 100,
                WidthCategory: 275,
                WidthService: 250,
                LeftPaddingCategory: 0,
                LeftPaddingService: 0
            }
        };
    }

    static confirmSave(alertCtrl: AlertController, message: string = null, translate: TranslateService = null): Observable<boolean> {
        return new Observable(observer => {
            alertCtrl.create({
                message: message || 'Do you confirm the changes?',
                buttons: [
                    {
                        text: translate ? translate.instant('dismiss') : 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            observer.next(false);
                        }
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            observer.next(true);
                        }
                    }]
            }).then(alert => alert.present());
        });
    }

    static getHeightWidthFlipVertical(heightForce: number = null, lessHeight = false): { width: number, height: number } {
        const format = 1.4142857;
        let height: number;

        if (heightForce) {
            height = window.innerHeight * heightForce;
        } else {
            if (window.innerHeight > 1200) {
                height = 1140;
                if (lessHeight) {
                    height = 820;
                }
            } else if (window.innerHeight > 992) {
                height = 960;
                if (lessHeight) {
                    height = 800;
                }
            } else if (window.innerHeight > 768) {
                height = 720;
                if (lessHeight) {
                    height = 620;
                }
            } else if (window.innerHeight > 576) {
                height = 540;
                if (lessHeight) {
                    height = 480;
                }
            } else {
                height = window.innerHeight * 0.75;
            }
        }
        if ((height / format) > window.innerWidth) {
            if (!heightForce) {
                heightForce = 0.95;
            }
            heightForce -= 0.10;
            return Utility.getHeightWidthFlipVertical(heightForce);
        }
        return {width: height / format, height};
    }

    static getHeightWidthFlip(widthForce: number = null, lessWidth = false): { width: number, height: number } {
        const format = 1.7777644;
        let width: number;

        if (widthForce) {
            width = window.innerWidth * widthForce;
        } else {
            if (window.innerWidth > 1200) {
                if (lessWidth) {
                    width = 820;
                } else {
                    width = 1140;
                }
            } else if (window.innerWidth > 992) {
                if (lessWidth) {
                    width = 700;
                } else {
                    width = 960;
                }
            } else if (window.innerWidth > 768) {
                if (lessWidth) {
                    width = 400;
                } else {
                    width = 720;
                }
            } else if (window.innerWidth > 576) {
                width = 560;
            } else {
                width = 270;
            }
        }
        if ((width / format) > window.innerHeight) {
            if (!widthForce) {
                widthForce = 0.75;
            }
            widthForce -= 0.10;
            return Utility.getHeightWidthFlip(widthForce, lessWidth);
        }
        return {width, height: width / format};
    }

    static getClassAction(
        act: TextAction,
        scaleFunc: (() => number),
        serviceFunc: ((serviceId: number) => ServiziCliente),
        categoryFunc: ((vategoryId: number) => CategoriaCliente),
        extractId = true) {
        const {
            width, height, left, top, action, link, color, photos, pageSelected, fontSize,
            fontColor, fontWeight, fontStyle, fontAlign, text, text_de, text_en, text_es, text_fr,
            text_pt, lineHeight, letterSpacing, background,
            widthPercent, heightPercent, leftPercent, topPercent, idService, srcImage, opacity,
            fontFamily, srcVideo, imgText, showText, name, name_de, name_en, name_es,
            name_fr, name_pt, images, idCategory, price, screenOriginHeight,
            borderPX, borderColor, showAnimation, isCreatedSede, isLock, IdPagesTemplate, IdTextActionSede, SyncType, Id,
            ParentCategoryId,
            Order,
            BoxTypeId
        } = act as TextAction;

        const textAction = new TextAction(width, height, left, top, action, link,
            color, photos, pageSelected, text, text_de, text_en, text_es, text_fr,
            text_pt, fontSize, fontColor, fontAlign, fontStyle, fontWeight, lineHeight,
            letterSpacing, background, widthPercent, leftPercent, heightPercent, topPercent, idService, srcImage,
            opacity, fontFamily, srcVideo, imgText, showText, name, name_de, name_en, name_es,
            name_fr, name_pt, images, idCategory, price, screenOriginHeight,
            borderPX, borderColor, showAnimation, isCreatedSede, isLock, IdPagesTemplate, IdTextActionSede, SyncType);
        textAction.scaleFunc = scaleFunc;
        textAction.serviceFunc = serviceFunc;
        textAction.categoryFunc = categoryFunc;
        textAction.ParentCategoryId = ParentCategoryId;
        textAction.Order = Order;
        if (extractId) {
            textAction.Id = Id;
        }

        textAction.BoxTypeId = BoxTypeId;

        return textAction;
    }

    static exportTextActions(textActions: TextAction[],
                             isVertical: boolean,
                             scaleFunc: (() => number),
                             serviceFunc: ((serviceId: number) => ServiziCliente),
                             categoryFunc: ((categoryId: number) => CategoriaCliente),
                             extractId = true,
                             widthHeight: { width: number, height: number } = null): TextAction[] {

        const newTextActions: TextAction[] = [];
        if (textActions) {

            // @ts-ignore
            // const textActionsPage = JSON.parse(currentPageInfo.TextActions) as TextAction[];

            for (const act of textActions) {
                const textAction = Utility.getClassAction(act, scaleFunc, serviceFunc, categoryFunc, extractId);
                if (isVertical) {
                    textAction.adjustPercent(widthHeight || Utility.getHeightWidthFlipVertical());
                } else {
                    // textAction.adjustPercent(widthHeight || Utility.getHeightWidthFlip(null, true));
                }
                newTextActions.push(textAction);
            }
        }
        return newTextActions;
    }

    static getConstantDimentions() {
        const format = 1.4142857;
        let height = 720;
        return {width: height / format, height};
    }

    static async setHtmlByTextActions(pages: PageTemplate[], resp: PagesListino,
                                      widthHeight: { width: number, height: number }, usersService: UsersService) {

        for (const page of pages) {
            const index = resp.PagesTemplate.findIndex(e => e.Id === page.Id);
            if (index === -1) {
                continue;
            }
            let actions = '';

            page.TextActions
                .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                );
            page.TextActions = Utility.exportTextActions(page.TextActions, true, () => 1, id => null, id => null);

            for (const el of page.TextActions) {
                actions += await el.getDivAction(widthHeight.width, widthHeight.height,
                    usersService.currentUserInfo.StorageAccount, usersService);
            }
            resp.PagesTemplate[index].textActions = page.TextActions;
            resp.PagesTemplate[index].HtmlPage = '<div style="width: 100%; height: 100%; display: flex; position: relative; background:white;"> '
                + actions +
                '</div>';
        }
    }

    static async setHtmlCatalogByTextActions(page: Catalog, widthHeight: { width: number, height: number }, usersService: UsersService) {
        let actions = '';

        page.TextActions
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        page.TextActions = Utility.exportTextActions(page.TextActions, true, () => 1, id => null, id => null, true, widthHeight);

        for (const el of page.TextActions) {
            actions += await el.getDivAction(widthHeight.width, widthHeight.height,
                usersService.currentUserInfo.StorageAccount, usersService);
        }
        page.HtmlPage = '<div style="width: 100%; height: 100%; display: flex; position: relative; background:white;"> '
            + actions +
            '</div>';
    }

    static matchDisabled(values: CategoriaCliente[], currentIndex: number, listinos: IMenuTemplatePage[]): CategoriaCliente[] {
        // const ids = listinos
        //   .filter((el, index) => index !== currentIndex)
        //   .map(e => ({...e, TextActions: e.TextActions.filter(el => el.idService !== null)}))
        //   .map(e => e.TextActions.map(a => a.idService))
        //   .reduce((accumulator, value) => accumulator.concat(value), []);

        // const idsCategory = listinos
        //   .filter((el, index) => index !== currentIndex)
        //   .map(e => ({...e, TextActions: e.TextActions.filter(el => el.idCategory !== null)}))
        //   .map(e => e.TextActions.map(a => a.idCategory))
        //   .reduce((accumulator, value) => accumulator.concat(value), []);

        // values.sort((a, b) => {
        //   if (a.IsVisibleToCustomer && b.IsVisibleToCustomer) {
        //     return a.Nome > b.Nome ? 1 : -1;
        //   } else if (a.IsVisibleToCustomer) {
        //     return -1;
        //   } else {
        //     return 1;
        //   }
        // });
        // for (const cat of values) {
        //   cat.disabled = idsCategory.includes(cat.Id) || idsCategory.includes(cat.IdCategoriaSede);
        //   cat.ServiziCliente = cat.ServiziCliente.map(e => ({...e, disabled: (ids.includes(+e.Id) || ids.includes(+e.IdServizioSede))}));
        //   cat.ServiziCliente.sort((a, b) => {
        //     if (a.IsVisibleToCustomers && b.IsVisibleToCustomers) {
        //       return a.Nome > b.Nome ? 1 : -1;
        //     } else if (a.IsVisibleToCustomers) {
        //       return -1;
        //     } else {
        //       return 1;
        //     }
        //   });
        // }
        return values;
    }

    static convertPxSize(htmlPage: string, stringReplace: string,
                         propertyStyle: 'font-size' | 'line-height' | 'letter-spacing' | 'padding-top', forceHeight: number = null): string {
        const index = htmlPage.indexOf(stringReplace);
        if (index > -1) {
            const endIndex = htmlPage.indexOf(')*', index);
            const subString = htmlPage.substring(index + stringReplace.length, endIndex);
            const screenAndFont = subString.split(',');
            const pxSize = +screenAndFont[1] * forceHeight / 720;
            htmlPage = htmlPage.replace(stringReplace + subString + ')*', propertyStyle + ':' + pxSize + 'px');
            return this.convertPxSize(htmlPage, stringReplace, propertyStyle, forceHeight);
        } else {
            return htmlPage;
        }
    }

    static async managePreview(
        templateService: TemplateService,
        previewPageComponent: PreviewPageComponent,
        currentPageInfo: PageTemplate | TemplatePageEditModel,
        isSede = false,
        usersService: UsersService,
        idTemplate?: number
    ) {
        let textActions;

        if ((currentPageInfo as PageTemplate).TextActions) {
            textActions = (currentPageInfo as PageTemplate).TextActions;
        } else {
            textActions = (currentPageInfo as TemplatePageEditModel).textActions;
        }

        if (textActions) {
            for (const action of textActions) {
                if (action.photos) {
                    for (let index = 0; index < action.photos.length; index++) {
                        if (action.photos[index].includes('base64,')) {
                            await templateService.savePhoto(action.photos[index], undefined, undefined, undefined, undefined, undefined, idTemplate).toPromise().then(resp => {
                                action.photos[index] = resp.Url;
                            });
                        }
                    }
                }
                if (action.images) {
                    for (let index = 0; index < action.images.length; index++) {
                        if (action.images[index].includes('base64,')) {
                            const metaData = {nameGallery: action.linkGalleryImages[index]};
                            await templateService.savePhoto(action.images[index], metaData,
                                false, action.compressionImages[index], action.multiTypeImages[index], idTemplate).toPromise().then(resp => {
                                resp.Url = resp.Url.replace(usersService.currentUserInfo.StorageAccount, '');
                                action.images[index] = resp.Url;
                                if (isSede) {
                                    action.putImages.push(resp.Url);
                                }
                            });
                        }
                    }
                }
            }
        }
    }

    static editAlert(alertCtrl: AlertController, value: string, description: string, translate: TranslateService):
        Observable<{ title: string, description: string }> {
        return new Observable(observer => {
            const inputs = [{
                name: 'title',
                placeholder: translate.instant('namePlaceholder'),
                value
            }];
            if (description !== null) {
                inputs.push({
                    name: 'description',
                    placeholder: translate.instant('descPlaceholder'),
                    value: description,
                });
            }
            alertCtrl.create({
                message: translate.instant('insertData'),
                inputs,
                buttons: [
                    {
                        text: translate.instant('dismiss'),
                        role: 'cancel',
                        handler: data => {
                            observer.next(null);
                        }
                    },
                    {
                        text: translate.instant('done'),
                        handler: data => {
                            observer.next({title: data.title, description: data.description});
                        }
                    }]
            }).then(alert => alert.present());
        });
    }

    static scaleToFit(path: string, size) {

        let resolver = (data: string | PromiseLike<string>): void => {
        };
        const promise = new Promise<string>((resolve, reject) => {
            resolver = resolve;
        });

        const img = document.createElement('img');

        img.onload = () => {
            // Dynamically create a canvas element
            const canvas = document.createElement('canvas');

            let newHeight = img.naturalHeight;
            let newWidth = img.naturalWidth;

            const maxLength = Math.max(img.naturalHeight, img.naturalWidth);
            if (maxLength > size) {
                const resizeFactor = maxLength / size;
                newHeight = newHeight / resizeFactor;
                newWidth = newWidth / resizeFactor;
            }


            canvas.width = newWidth;
            canvas.height = newHeight;

            // let canvas = document.getElementById("canvas");
            const ctx = canvas.getContext('2d');

            // Actual resizing
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Show resized image in preview element
            const dataurl = canvas.toDataURL('image/jpeg', 0.8);

            resolver(dataurl);
        };

        img.src = path;


        return promise;
    }

    static removeEmptyStrings(obj: any) {
        if (Array.isArray(obj)) {
            return obj.map(Utility.removeEmptyStrings);
        } else if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce((acc, key) => {
                const value = obj[key];
                if (value === '') {
                    acc[key] = undefined;
                } else if (typeof value === 'object') {
                    acc[key] = Utility.removeEmptyStrings(value);
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {} as any);
        } else {
            return obj;
        }
    }
}
