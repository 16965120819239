import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable()
export class LoadingService {
    loading: HTMLIonLoadingElement;

    constructor(private loadingCtrl: LoadingController) {
    }

    show() {
        if (!this.loading) {
            this.loadingCtrl.create({
                message: 'Attendere',
                translucent: true
            }).then(ctrlRef => {
                this.loading = ctrlRef;
                this.loading.present().then()
            });
        }
    }

    hide() {
        if (this.loading) {
            this.loading.dismiss().then(() => {
                this.loading = null;
            });
        } else {
            setTimeout(() => {
                this.hide();
            }, 100);
        }
    }
}
