import {Component, OnInit} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {TemplateService} from '../../services/template.service';
import {UsersService} from '../../services/users.service';
import {MenusService} from '../../services/menus.service';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

    init: Promise<boolean>;

    constructor(
        public templateService: TemplateService,
        public usersService: UsersService,
        public menusService: MenusService,
    ) {
    }

    async ngOnInit() {

        this.init = (async () => {
            this.menusService.currentMenu = await firstValueFrom(this.menusService.getCurrentMenu());
            // this.templateService.menuModel = await firstValueFrom(this.templateService.getMenuModelOfClient());
            this.usersService.languages = await firstValueFrom(this.usersService.getLanguages());
            return true;
        })();

    }

}
