export interface PhotoDTO {
    From: string;
    ServiceId: number;
    HasCustomer: boolean;
    HasLike: boolean;
    HasStar: boolean;
    Description: string;
    FileName: string;
    Tags: number[];
    Base64Data: string;
}
export interface Photo {
    Id: string;
    Name: string;
    Path: string;
    Url: string;
    OriginalFileName: string;
    Star: boolean;
    ShopHeart: boolean;
    Tags: number[];
    ThumbnailUrl: string;
    ServerModified: Date;
    IsShared: boolean;
}
export enum TypePhoto {
    Personal = 1, Library = 2
}
