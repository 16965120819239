import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storage$ = new BehaviorSubject<Storage>(null);

    constructor(private storageReader: Storage) {
    }

    init() {
        return new Promise((resolve) => {
            this.storageReader.create().then(storage => {
                this.storage$.next(storage);
                resolve(true);
            });
        })
    }

    public get(key: StorageKeys): Promise<string> {
        return this.storage$.value.get(key);
    }

    public set(key: StorageKeys, value: any): Promise<any> {
        return this.storage$.value.set(key, value)
    }

    public remove(key: StorageKeys): Promise<any> {
        return this.storage$.value.remove(key);
    }
}

export enum StorageKeys {
    user = 'user',
    username = 'username',
    pwd = 'password',
    autoLogin = 'autoLogin',
    chatBlockeds = 'chatBlockeds'
}
