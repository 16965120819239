import {Platform} from '@ionic/angular';
import {AppConfig} from './app.config';
import {ElementRef} from '@angular/core';
import html2canvas from 'html2canvas';

export class AppHelper {
    public static redirectUrl(url: string, isOpen: boolean = false) {
        if (!url) return;

        if (isOpen) {
            window.open(url);
        } else {
            window.location.href = url;
        }
    }

    public static getUserAgent(): string {
        return navigator.userAgent;
    }

    public static redirectToAppleStore() {
        this.redirectUrl(AppConfig.AppStore, true);
    }

    public static redirectToGoogleStore() {
        this.redirectUrl(AppConfig.GoogleStore);
    }

    public static isIOS(): RegExpMatchArray {
        return this.getUserAgent().match(/(iPod|iPhone|iPad)/);
    }

    public static isAndroid(): boolean {
        return this.getUserAgent().toLowerCase().indexOf('android') > -1
    }

    public static storeRedirectionHandler(platform: Platform) {
        if (platform.is('android')) {
            AppHelper.redirectToGoogleStore();
        } else if (
            platform.is('ios') &&
            (
                platform.is('iphone') ||
                platform.is('ipad')
            )
        ) {
            AppHelper.redirectToAppleStore();
        }
    }

    /// Returns the component as an image in base64 format
    static async getComponentAsImage(elementRef: ElementRef) {
        const element = elementRef.nativeElement;
        let base64: string;

        await html2canvas(element, {
            useCORS: true,
        }).then(canvas => {
            base64 = canvas.toDataURL('image/png');
        });

        return base64;
    }

}
